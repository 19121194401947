import React, { useRef, useEffect } from "react"
import tw from "twin.macro"
import { isBrowser, isEDMode } from "@components/utils"

import arrowIcon from "@images/icons/icon-arrow-left-purple.svg"

const ScrollChart = ({ children, theme }) => {
  const container = useRef()
  const content = useRef()

  useEffect(() => {
    if (isBrowser && isEDMode) {
      if (window.location.search.indexOf("scroll-left-1") > -1) {
        container.current.scrollLeft = 240
      } else if (window.location.search.indexOf("scroll-left-2") > -1) {
        container.current.scrollLeft = 440
      } else {
        container.current.scrollLeft = content.current.scrollWidth
      }
    }
  }, [])

  return (
    <div>
      <p
        css={[
          tw`text-[12px] text-studio font-bold uppercase py-1 pr-2.5 w-full tracking-wider flex items-center leading-normal md:hidden`,
          theme === "ih" &&
            tw`text-hcpPurple honey-lavender-gradient-ih rounded-[8px] w-[265px] h-[30px] px-2.5`,
        ]}
      >
        <img src={arrowIcon} alt="" tw="mr-2.5" />
        <span>
          {theme === "ih"
            ? "Swipe to view rest of chart"
            : "Scroll to view chart"}
        </span>
      </p>
      <div
        css={[
          tw`rounded-3xl`,
          theme === "ih"
            ? tw`pt-4 mb-2.5 pr-3.5 md:(py-0 pr-0)`
            : tw`py-2.5 xl:py-0`,
        ]}
      >
        {/* Scroll container on mobile */}
        <div
          className="scroll-container"
          css={[
            tw`overflow-x-auto scrollbar-thin scrollbar-thumb-hcpLilac scrollbar-thumb-rounded-xl scrollbar-track-hcpLilacTransparency md:(overflow-hidden)`,
            theme === "ih" &&
              tw`scrollbar-thumb-studio scrollbar-track-scrollTrackPurpleIH mb-2.5`,
          ]}
          ref={container}
        >
          <div ref={content}>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default ScrollChart
